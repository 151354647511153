<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="posts.data"
      :server-items-length="posts.meta.total"
      :sort-desc="true"
      :footer-props="{
        itemsPerPageOptions: [10, 50, 100],
      }"
      :options.sync="options"
      :sort-by="'updated_at'"
    >
      <template v-slot:item.updated_at="{ item }">{{
        item.updated_at | dateFormatted
      }}</template>

      <template v-slot:item.action="{ item }">
        <div class="text-center">
          <v-list-item @click="$emit('edit', item)">
            <v-list-item-title>
              <v-icon class="mr-1" small>mdi-pencil</v-icon>
              {{ $t('ITEM.EDIT') }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { cloneDeep, isEmpty } from 'lodash';

export default {
  name: 'PostsList',

  filters: {
    dateFormatted(value) {
      if (!value) return '';

      return moment(value).format('DD.MM.YYYY');
    },
  },

  props: {
    posts: {
      type: Object,
      default: () => ({
        data: [],
        meta: {
          total: 0,
        },
      }),
    },
  },

  data: () => ({
    unwatch: null,
    options: {},
  }),

  computed: {
    ...mapState('post', {
      prevOptions: 'options',
    }),
    headers() {
      return [
        {
          text: this.$t('ITEM.ID'),
          align: 'left',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('ITEM.AUTHOR'),
          value: 'author.name',
        },
        {
          text: this.$t('ITEM.TITLE'),
          value: 'name',
        },
        {
          text: this.$t('ITEM.UPDATED_AT'),
          value: 'updated_at',
        },
        {
          text: this.$t('DATA_TABLE.ACTIONS'),
          value: 'action',
          sortable: false,
          align: 'center',
        },
      ];
    },
    params() {
      const options = this.options;

      return {
        page: options.page,
        per_page: options.itemsPerPage,
        sort_by: options.sortBy[options.sortBy.length - 1],
        order_by: options.sortDesc[options.sortDesc.length - 1]
          ? 'desc'
          : 'asc',
      };
    },
  },

  watch: {
    options: {
      handler() {
        this.refreshData();
      },
      deep: true,
    },
  },

  created() {
    if (!isEmpty(this.prevOptions)) {
      this.options = cloneDeep(this.prevOptions);
    }

    this.unwatch = this.$store.watch(
      (state) => state.localization.lang,
      () => {
        const lang = this.$store.state.localization.lang;

        if (lang) {
          this.refreshData();
        }
      }
    );
  },

  destroyed() {
    this.unwatch();
  },

  beforeDestroy() {
    this.$store.commit('post/pageOptions', cloneDeep(this.options));
  },

  methods: {
    refreshData() {
      this.$emit('refresh', {
        params: Object.assign({}, this.params),
      });
    },
  },
};
</script>
